import { useEffect, useState } from "react";
import MainLayout from "../components/layout";
import { API } from "../config";
import axios from "axios";
const DeliveryItem = ({ delivery, user, order, products }) => {
  const [claiming, setClaiming] = useState(false);
  const claimDelivery = async () => {
    let user = localStorage.getItem("user");
    if (!user) {
      window.location.href = "/signin";
    }
    user = JSON.parse(user);

    setClaiming(true);
    try {
      const response = await axios.post(
        `${API}/delivery/claim-delivery`,
        {
          id: delivery._id,
          userId: user._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response) {
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
    setClaiming(false);
  };
  return (
    <tr className="bg-white border-b   hover:bg-gray-50 ">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
      >
        {user?.firstname} {user?.lastname}
      </th>
      <td className="px-6 py-4">{user?.phoneNumber}</td>
      <td className="px-6 py-4">{user?.location}</td>
      <td className="px-6 py-4">
        {products?.map((item) => (
          <div key={item?.product._id} className="flex gap-2 items-center">
            <div className="border-b">
              <p>Store Name: {item?.store?.name}</p>
              <p>Store Location: {item?.store?.location}</p>
              <p>Store Number: {item?.store?.phoneNumber}</p>
              <p>Product Name: {item?.product?.name}</p>
              <p>X</p>
              <p>{item?.cart?.numberOfItems}</p>
            </div>
          </div>
        ))}
      </td>
      <td className="px-6 py-4">{order?.amount}Frs</td>
      <td className="flex items-center px-6 py-4">
        <button
          disabled={claiming}
          onClick={() => claimDelivery()}
          className={` text-[12px] capitalize px-4 py-1 rounded-md bg-yellow-200 text-yellow-700`}
        >
          Claim Delivery
        </button>
      </td>
    </tr>
  );
};

const Deliveries = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deliveries, setDeliveries] = useState([]);
  useEffect(() => {
    const listDelivery = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        }
        user = JSON.parse(user);
        console.log("user", user);
        const response = await axios.post(
          `${API}/delivery/list`,
          {
            userId: user._id,
            token: user.originToken,
            region: user.region,
            page: "dashboard",
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        console.log("response", response);
        if (response.data.deliveries) {
          setDeliveries(response.data.deliveries);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error carts", error);
      }
    };
    listDelivery();
  }, []);

  console.log("response", deliveries);
  return (
    <>
      <MainLayout>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  User name
                </th>
                <th scope="col" className="px-6 py-3">
                  User phonenumber
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Products
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {deliveries.map((item) => (
                <DeliveryItem
                  key={item.delivery._id}
                  delivery={item.delivery}
                  user={item.user}
                  order={item.order}
                  products={item.products}
                />
              ))}
            </tbody>

            {deliveries.length === 0 && (
              <div className="pt-16 md:text-center">
                {isLoading ? (
                  <p>Please wait...</p>
                ) : (
                  <p>No deliveries available</p>
                )}
              </div>
            )}
          </table>
        </div>
      </MainLayout>
    </>
  );
};
export default Deliveries;
