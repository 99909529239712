import { useEffect, useState } from "react";
import MainLayout from "../components/layout";
import { API } from "../config";
import axios from "axios";
import { Link } from "react-router-dom";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        } else {
          setUser(JSON.parse(user));
        }
      } catch (err) {}
    };
    getUser();
  }, []);

  const deleteAccount = async () => {
    try {
      await axios.post(
        `${API}/user/delete`,
        {
          id: user._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      localStorage.removeItem("user");
      window.location.href = "/";
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MainLayout>
        <div class="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-6">
          <div class="col-span-full mb-5 xl:mb-0">
            <nav class="flex mb-5" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-2">
                <li class="inline-flex items-center">
                  <a
                    href="/dashboard"
                    class="inline-flex items-center text-gray-700 hover:text-gray-900"
                  >
                    <svg
                      class="w-5 h-5 mr-2.5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>

                <li>
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span
                      class="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                      aria-current="page"
                    >
                      Profile
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">
              Profile
            </h1>
          </div>

          <div class="col-span-full xl:col-auto">
            <div class="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
              <div class="sm:flex xl:block sm:space-x-4 xl:space-x-0">
                <img
                  class="mb-2 w-20 h-20 rounded-2xl shadow-lg shadow-gray-300"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="Jese portrait"
                />
                <div>
                  <h2 class="text-xl font-bold">
                    {user?.firstname} {user?.lastname}
                  </h2>
                  <ul class="mt-2 space-y-1">
                    <li class="flex items-center text-sm font-normal text-gray-500">
                      <svg
                        class="mr-2 w-4 h-4 text-gray-900"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clip-rule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                      {user?.role}
                    </li>
                    <li class="flex items-center text-sm font-normal text-gray-500">
                      <svg
                        class="mr-2 w-4 h-4 text-gray-900"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      {user?.location}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mb-4 sm:flex xl:block">
                <div class="sm:flex-1">
                  <address class="text-sm not-italic font-normal text-gray-500">
                    <div class="mt-4">Email adress</div>
                    <a
                      class="text-sm font-medium text-gray-900"
                      href="mailto:webmaster@flowbite.com"
                    >
                      {user?.email}
                    </a>
                    <div class="mt-4">Region</div>
                    <div class="mb-2 text-sm font-medium text-gray-900">
                      {user?.region}
                    </div>
                    <div class="mt-4">Phone number</div>
                    <div class="mb-2 text-sm font-medium text-gray-900">
                      {user?.phoneNumber}
                    </div>
                  </address>
                </div>
              </div>
              <div>
                <h3 class="mb-2 text-base font-bold text-gray-900">Actions</h3>
                <div class="flex space-x-1">
                  <Link to="/edit">
                    <button
                      type="button"
                      class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                      Edit
                    </button>
                  </Link>
                  <button
                    type="button"
                    class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    disabled={isDeleting}
                    onClick={() => deleteAccount()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
export default Profile;
