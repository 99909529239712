import Logo from "../images/logo/grabit.png";
import { useState } from "react";
import { API } from "../config";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    setError("");
    setIsLoading(true);

    try {
      // Form is valid, you can submit or process the data here
      const response = await axios.post(`${API}/user/forgotpassword`, {
        email: email,
      });
      console.log("response", response);
      setSuccess(response.data.message);
    } catch (error) {
      console.log("error", error);

      if (error.code !== "ERR_NETWORK") {
        setError(error.response.data.message);
      } else {
        setError("Network Error");
      }
    }
    console.log("values", email);
    setIsLoading(false);
  };
  return (
    <>
      <section className="bg-center bg-no-repeat background h-screen md:pb-16 overflow-hidden ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <img className="h-32 -mb-5" src={Logo} alt="logo" />
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl text-center -mb-2 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Forgot password
              </h1>
              <form
                onSubmit={handleSubmit}
                className="space-y-4 md:space-y-6"
                action="#"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Submit
                </button>

                {success && <div className="text-green-500">{success}</div>}

                {error && (
                  <div className="text-red-500">
                    <p>{error}</p>
                  </div>
                )}

                <p className="text-sm font-light text-gray-500">
                  Back to sign in{" "}
                  <a
                    href="/"
                    className="font-medium text-green-600 hover:underline "
                  >
                    Sign in
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPassword;
