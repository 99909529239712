import { useEffect, useState } from "react";
import MainLayout from "../components/layout";
import { API } from "../config";
import axios from "axios";
import { Link } from "react-router-dom";

const Payments = () => {
  const [order, setOrder] = useState({});
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState({
    totalAmountToWithdraw: 0,
    totalAmountWithdrawn: 0,
    totalNumberOfOrders: 0,
    undeliveredOrders: 0,
    deliveredOrders: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }
  useEffect(() => {
    const listOrders = async () => {
      try {
        if (!user || user.role !== "deliveryAgent") {
          window.location.href = "/";
        }
        const orders = await axios.post(
          `${API}/order/delivery-order-details`,
          {
            userId: user._id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        console.log("orders", orders);
        setOrder(orders.data.details);
        const neworders = orders.data.details;
        setInfo({
          totalAmountToWithdraw: neworders?.totalAmountToWithdraw,
          totalAmountWithdrawn: neworders?.totalAmountWithdrawn,
          totalNumberOfOrders: neworders?.totalOrders,
          undeliveredOrders: neworders?.undeliveredOrders,
          deliveredOrders: neworders?.deliveredOrders,
        });
      } catch (err) {
        console.log("cart", err);
      }
    };
    listOrders();
  }, []);

  const initiateWidthdrawal = async () => {
    setIsSubmitting(true);
    try {
      await axios.post(
        `${API}/payment/init-delivery-withdrawal`,
        {
          userId: user._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setSuccess(true);
    } catch (error) {
      console.log("error", error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <MainLayout>
        <div class="md:px-4 pt-6">
          <div class="col-span-full mb-5 xl:mb-0">
            <nav class="flex mb-5" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-2">
                <li class="inline-flex items-center">
                  <a
                    href="/dashboard"
                    class="inline-flex items-center text-gray-700 hover:text-gray-900"
                  >
                    <svg
                      class="w-5 h-5 mr-2.5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>

                <li>
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span
                      class="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                      aria-current="page"
                    >
                      Payments
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl">
              Payments details
            </h1>
          </div>

          <section class="relative z-10 after:contents-[''] max-md:-mt-3 pt-5 md:pt-10 after:absolute after:z-0 after:h-full after:top-0 after:right-0 after:bg-gray-50">
            <div class="w-full md:px-5 lg-6 mx-auto relative z-10">
              <div class="grid grid-cols-12">
                <div class="col-span-12 xl:col-span-8 lg:pr-8 pb-8 md:pt-5 pb:py-24 w-full max-xl:max-w-3xl max-xl:mx-auto">
                  <div className="w-full flex items-center justify-between border-b py-5">
                    <div class="w-full">
                      <p>Total number of Orders:</p>
                    </div>
                    <p class="font-bold text-lg leading-8 text-gray-600 text-center transition-all duration-300 group-hover:text-green-600">
                      {info?.totalNumberOfOrders}
                    </p>
                  </div>
                  <div className="w-full flex items-center justify-between border-b py-5">
                    <div class="w-full">
                      <p>Total number of Unpaid Orders:</p>
                    </div>
                    <p class="font-bold text-lg leading-8 text-gray-600 text-center transition-all duration-300 group-hover:text-green-600">
                      {info?.undeliveredOrders}
                    </p>
                  </div>
                  <div className="w-full flex items-center justify-between border-b py-5">
                    <div class="w-full">
                      <p>Total number of Paid Orders:</p>
                    </div>
                    <p class="font-bold text-lg leading-8 text-gray-600 text-center transition-all duration-300 group-hover:text-green-600">
                      {info?.deliveredOrders}
                    </p>
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-4 md:pr-8 bg-gray-50 w-full max-xl:px-6 max-w-3xl xl:max-w-lg mx-auto lg:pl-8 pt-6 pb-24">
                  <h2 class="font-manrope font-bold text-3xl leading-10 text-black pb-8 border-b border-gray-300">
                    Summary
                  </h2>
                  <div class="mt-8">
                    <div class="flex items-center justify-between pb-6">
                      <p class="font-normal text-lg leading-8 text-black">
                        Account Balance
                      </p>
                      <p class="font-medium text-lg leading-8 text-black">
                        XAF {info?.totalAmountToWithdraw}
                      </p>
                    </div>
                    <div class="flex items-center justify-between pb-6">
                      <p class="font-normal text-lg leading-8 text-black">
                        Total Withdrawals
                      </p>
                      <p class="font-medium text-lg leading-8 text-black">
                        XAF {info?.totalAmountWithdrawn}
                      </p>
                    </div>
                    <button
                      disabled={
                        info?.totalAmountToWithdraw <= 0 || isSubmitting
                      }
                      onClick={() => initiateWidthdrawal()}
                      class="w-full text-center bg-green-600 rounded-xl hover:cursor-pointer py-3 px-6 font-semibold text-lg text-white transition-all duration-500 hover:bg-green-700"
                    >
                      Checkout
                    </button>
                    <div>
                      {success && (
                        <p className="text-green-500 pt-3">
                          Withdrawal successful. Check your momo number:{" "}
                          {user.phoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};
export default Payments;
