import { useState } from "react";
import MainLayout from "../components/layout";
import { API } from "../config";
import axios from "axios";
const Edit = () => {
  const regions = [
    "Adamawa Region",
    "Centre Region",
    "East Region",
    "Far North Region",
    "Littoral Region",
    "North Region",
    "Northwest Region",
    "South Region",
    "Southwest Region",
    "West Region",
  ];

  let user = localStorage.getItem("user");
  if (!user) {
    window.location.href = "/signin";
  } else {
    user = JSON.parse(user);
  }

  const [values, setValues] = useState({
    id: user?._id,
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    location: user?.location,
    carCategory: user?.carCategory,
    numberplateLicence: user?.numberplateLicence,
    region: user?.region,
    token: user.originToken,
  });

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    setValues({ ...values, [event.name]: event.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    setIsLoading(true);

    try {
      const editUser = await axios.post(`${API}/user/update`, values, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (editUser.data.success) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(editUser.data.user));
        console.log("currentUser", editUser.data.user);
        window.location.href = "/profile";
      } else {
        setError(editUser.error.response.data.errors);
      }
    } catch (error) {
      console.log("editUser", error);
    }
    setIsLoading(false);
  };

  console.log("user", user);
  return (
    <>
      <MainLayout>
        <div className="">
          <div class="col-span-full mb-5 xl:mb-0">
            <nav class="flex mb-5" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-2">
                <li class="inline-flex items-center">
                  <a
                    href="/dashboard"
                    class="inline-flex items-center text-gray-700 hover:text-gray-900"
                  >
                    <svg
                      class="w-5 h-5 mr-2.5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>

                <li>
                  <div class="flex items-center">
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span
                      class="ml-1 text-sm font-medium text-gray-400 md:ml-2"
                      aria-current="page"
                    >
                      Edit profile
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div className="w-full bg-white rounded-lg  md:mt-0 xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                <div className="md:flex max-md:space-y-4 gap-3 w-full">
                  <div className="w-full">
                    <label
                      htmlFor="firstname"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      value={values.firstname}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your first name"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="lastname"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      value={values.lastname}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your last name"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                </div>
                <div className="md:flex max-md:space-y-4 gap-3 w-full">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your email"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="phoneNumber"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={values.phoneNumber}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your phone number"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                </div>
                <div className="md:flex max-md:space-y-4 gap-3 w-full">
                  <div className="w-full">
                    <label
                      htmlFor="location"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      value={values.location}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your location"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="region"
                      className="block text-sm mb-2 font-medium text-gray-900 "
                    >
                      Select region
                    </label>
                    <select
                      id="region"
                      name="region"
                      value={values.region}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 "
                      onChange={(e) => handleChange(e.target)}
                    >
                      {regions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex max-md:space-y-4 gap-3 w-full">
                  <div className="w-full">
                    <label
                      htmlFor="carCategory"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Car Category
                    </label>
                    <input
                      type="text"
                      name="carCategory"
                      id="carCategory"
                      value={values.carCategory}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your car category"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="numberplateLicence"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Number plate licence
                    </label>
                    <input
                      type="text"
                      name="numberplateLicence"
                      id="numberplateLicence"
                      value={values.numberplateLicence}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                      placeholder="Enter your number plate licence"
                      required
                      onChange={(e) => handleChange(e.target)}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Update
                </button>

                {error && (
                  <div className="text-red-500">
                    {Object.keys(error).map((keyName, i) => (
                      <p>{error[keyName]}</p>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
export default Edit;
