import { useState } from "react";
import Logo from "../images/logo/grabit.png";
import { API } from "../config";
import axios from "axios";
const SignUp = () => {
  const regions = [
    "Adamawa Region",
    "Centre Region",
    "East Region",
    "Far North Region",
    "Littoral Region",
    "North Region",
    "Northwest Region",
    "South Region",
    "Southwest Region",
    "West Region",
  ];
  const [passwordVisibility, setPasswordVisibility] = useState("password");
  const [conPasswordVisibility, setConPasswordVisibility] =
    useState("password");

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    location: "",
    password: "",
    nationalID: "",
    carCategory: "",
    numberplateLicence: "",
    region: "Southwest Region",
    role: "deliveryAgent",
  });

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [verify, setVerify] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleChange = (event) => {
    setValues({ ...values, [event.name]: event.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    setIsLoading(true);
    if (values.password !== confirmPassword) {
      setError({ confirmPassword: "Confirm password must match password" });
      return;
    }

    try {
      const createResult = await axios.post(`${API}/user/signup`, values);
      console.log("createResult", createResult);
      setVerify(
        `Your account has been created successfully and ${createResult.data.message}`
      );
      setValues({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        location: "",
        password: "",
        nationalID: "",
        carCategory: "",
        numberplateLicence: "",
        region: "Southwest Region",
        role: "deliveryAgent",
      });
      setConfirmPassword("");
    } catch (error) {
      console.log(error);
      if (error.code !== "ERR_NETWORK") {
        setError(error.response.data.errors);
      } else {
        setError({ signup: "Network Error" });
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="bg-center bg-no-repeat background h-screen md:pb-16 overflow-x-hidden overflow-y-auto">
      <div className="flex flex-col items-center justify-center px-6 py-8  mx-auto h-fit lg:py-0">
        <img className="h-32 -mb-5" src={Logo} alt="logo" />
        <div className="w-full bg-white rounded-lg shadow md:min-w-[600px] md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Create an account
            </h1>
            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
              <div className="md:flex max-md:space-y-4 gap-3 w-full">
                <div className="w-full">
                  <label
                    htmlFor="firstname"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={values.firstname}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your first name"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="lastname"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={values.lastname}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your last name"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
              </div>
              <div className="md:flex max-md:space-y-4 gap-3 w-full">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={values.email}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your email"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="phoneNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={values.phoneNumber}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your phone number"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
              </div>
              <div className="md:flex max-md:space-y-4 gap-3 w-full">
                <div className="w-full">
                  <label
                    htmlFor="location"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    id="location"
                    value={values.location}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your location"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="region"
                    className="block text-sm mb-2 font-medium text-gray-900 "
                  >
                    Select region
                  </label>
                  <select
                    id="region"
                    name="region"
                    value={values.region}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 "
                    onChange={(e) => handleChange(e.target)}
                  >
                    {regions.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="md:flex max-md:space-y-4 gap-3 w-full">
                <div className="w-full">
                  <label
                    htmlFor="carCategory"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Car Category
                  </label>
                  <input
                    type="text"
                    name="carCategory"
                    id="carCategory"
                    value={values.carCategory}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your car category"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="numberplateLicence"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Number plate licence
                  </label>
                  <input
                    type="text"
                    name="numberplateLicence"
                    id="numberplateLicence"
                    value={values.numberplateLicence}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    placeholder="Enter your number plate licence"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="nationalID"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  National ID number
                </label>
                <input
                  type="text"
                  name="nationalID"
                  id="nationalID"
                  value={values.nationalID}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                  placeholder="Enter your ID card number"
                  required
                  onChange={(e) => handleChange(e.target)}
                />
              </div>
              <div className="md:flex max-md:space-y-4 gap-3 w-full">
                <div className="w-full" style={{ position: "relative" }}>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Password (mininum of 6 letters)
                  </label>
                  <input
                    type={passwordVisibility}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    value={values.password}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    pattern=".{6,}"
                    required
                    onChange={(e) => handleChange(e.target)}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "1rem",
                      color: "black",
                      top: "2.5rem",
                      border: 0,
                      background: "transparent",
                    }}
                    onClick={() =>
                      passwordVisibility === "text"
                        ? setPasswordVisibility("password")
                        : setPasswordVisibility("text")
                    }
                    className="visibility"
                  >
                    {passwordVisibility === "text" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M53.92 34.62a8 8 0 1 0-11.84 10.76l19.24 21.17C25 88.84 9.38 123.2 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208a127.1 127.1 0 0 0 52.07-10.83l22 24.21a8 8 0 1 0 11.84-10.76Zm47.33 75.84l41.67 45.85a32 32 0 0 1-41.67-45.85M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.2 133.2 0 0 1 25 128c4.69-8.79 19.66-33.39 47.35-49.38l18 19.75a48 48 0 0 0 63.66 70l14.73 16.2A112 112 0 0 1 128 192m6-95.43a8 8 0 0 1 3-15.72a48.16 48.16 0 0 1 38.77 42.64a8 8 0 0 1-7.22 8.71a6 6 0 0 1-.75 0a8 8 0 0 1-8-7.26A32.09 32.09 0 0 0 134 96.57m113.28 34.69c-.42.94-10.55 23.37-33.36 43.8a8 8 0 1 1-10.67-11.92a132.8 132.8 0 0 0 27.8-35.14a133.2 133.2 0 0 0-23.12-30.77C185.67 75.19 158.78 64 128 64a118.4 118.4 0 0 0-19.36 1.57A8 8 0 1 1 106 49.79A134 134 0 0 1 128 48c34.88 0 66.57 13.26 91.66 38.35c18.83 18.83 27.3 37.62 27.65 38.41a8 8 0 0 1 0 6.5Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M247.31 124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57 61.26 162.88 48 128 48S61.43 61.26 36.34 86.35C17.51 105.18 9 124 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208s66.57-13.26 91.66-38.34c18.83-18.83 27.3-37.61 27.65-38.4a8 8 0 0 0 0-6.5M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.5 133.5 0 0 1 25 128a133.3 133.3 0 0 1 23.07-30.75C70.33 75.19 97.22 64 128 64s57.67 11.19 79.93 33.25A133.5 133.5 0 0 1 231.05 128c-7.21 13.46-38.62 64-103.05 64m0-112a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32"
                        />
                      </svg>
                    )}
                  </div>
                </div>

                <div className="w-full" style={{ position: "relative" }}>
                  <label
                    htmlFor="confirm-password"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Confirm password
                  </label>
                  <input
                    type={conPasswordVisibility}
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="••••••••"
                    value={values.confirmPassword}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 "
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "1rem",
                      color: "black",
                      top: "2.5rem",
                      border: 0,
                      background: "transparent",
                    }}
                    onClick={() =>
                      conPasswordVisibility === "text"
                        ? setConPasswordVisibility("password")
                        : setConPasswordVisibility("text")
                    }
                    className="visibility"
                  >
                    {conPasswordVisibility === "text" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M53.92 34.62a8 8 0 1 0-11.84 10.76l19.24 21.17C25 88.84 9.38 123.2 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208a127.1 127.1 0 0 0 52.07-10.83l22 24.21a8 8 0 1 0 11.84-10.76Zm47.33 75.84l41.67 45.85a32 32 0 0 1-41.67-45.85M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.2 133.2 0 0 1 25 128c4.69-8.79 19.66-33.39 47.35-49.38l18 19.75a48 48 0 0 0 63.66 70l14.73 16.2A112 112 0 0 1 128 192m6-95.43a8 8 0 0 1 3-15.72a48.16 48.16 0 0 1 38.77 42.64a8 8 0 0 1-7.22 8.71a6 6 0 0 1-.75 0a8 8 0 0 1-8-7.26A32.09 32.09 0 0 0 134 96.57m113.28 34.69c-.42.94-10.55 23.37-33.36 43.8a8 8 0 1 1-10.67-11.92a132.8 132.8 0 0 0 27.8-35.14a133.2 133.2 0 0 0-23.12-30.77C185.67 75.19 158.78 64 128 64a118.4 118.4 0 0 0-19.36 1.57A8 8 0 1 1 106 49.79A134 134 0 0 1 128 48c34.88 0 66.57 13.26 91.66 38.35c18.83 18.83 27.3 37.62 27.65 38.41a8 8 0 0 1 0 6.5Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M247.31 124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57 61.26 162.88 48 128 48S61.43 61.26 36.34 86.35C17.51 105.18 9 124 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208s66.57-13.26 91.66-38.34c18.83-18.83 27.3-37.61 27.65-38.4a8 8 0 0 0 0-6.5M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.5 133.5 0 0 1 25 128a133.3 133.3 0 0 1 23.07-30.75C70.33 75.19 97.22 64 128 64s57.67 11.19 79.93 33.25A133.5 133.5 0 0 1 231.05 128c-7.21 13.46-38.62 64-103.05 64m0-112a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-green-300 "
                    required=""
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className="font-light text-gray-500 ">
                    I accept the{" "}
                    <a
                      href="/"
                      className="font-medium text-green-600 hover:underline"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Create an account
              </button>

              {error && (
                <div className="text-red-500">
                  {Object.keys(error).map((keyName, i) => (
                    <p>{error[keyName]}</p>
                  ))}
                </div>
              )}
              {verify && (
                <div className="text-green-500">
                  <p>{verify}</p>
                </div>
              )}

              <p className="text-sm font-light text-gray-500 ">
                Already have an account?{" "}
                <a
                  href="/"
                  className="font-medium text-green-600 hover:underline "
                >
                  Sign in
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUp;
