import React, { useState } from "react";
import Logo from "../images/logo/grabit.png";
import { API } from "../config";
import axios from "axios";

const Header = ({ handleSideBar }) => {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const signOut = async () => {
    try {
      await axios.get(`${API}/user/signout`);
      localStorage.removeItem("user");
      window.location.href = "/";
      setIsSigningOut(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">
              <button
                type="button"
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                onClick={() => handleSideBar()}
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <img className="h-24 -mb-8 -mt-7" src={Logo} alt="logo" />
            </div>
            <div class="flex items-center">
              <button
                className="bg-red-700 rounded text-white px-4 py-1.5 font-medium "
                disabled={isSigningOut}
                onClick={() => signOut()}
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
