import "./App.css";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import VerifyEmail from "./pages/verify";
import NotFound from "./pages/404";
import Deliveries from "./pages/deliveries";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import Profile from "./pages/profile";
import Edit from "./pages/edit";
import Payment from "./pages/payments";
import DeliveryHistories from "./pages/delivery-history";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Deliveries />} />
        <Route path="/forgot-pass" element={<ForgotPassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/payments" element={<Payment />} />
        <Route path="/history" element={<DeliveryHistories />} />
        <Route
          path="/resetpassword/:value/:userId/:token"
          element={<ResetPassword />}
        />
        <Route path="/verify/:value/:userId/:token" element={<VerifyEmail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
