import { useState } from "react";
import Aside from "./aside";
import Header from "./header";

const MainLayout = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const handleSideBar = () => {
    setShowSideBar(!showSideBar);
  };
  return (
    <>
      <Header handleSideBar={handleSideBar} />
      <Aside showSideBar={showSideBar} />

      <div class="p-4 sm:ml-64">
        <div class="p-4  rounded-lg mt-14">{children}</div>
      </div>
    </>
  );
};
export default MainLayout;
