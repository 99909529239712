import { useState } from "react";
import Logo from "../images/logo/grabit.png";
import { API } from "../config";
import axios from "axios";
const VerifyEmail = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const url = window.location.href.split("/");
  const path = `${API}/user/verify/${url[4]}/${url[5]}/${url[6]}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const result = await axios.get(path);
      console.log("result:", result);
      if (result.status === 200) {
        window.location.href = "/";
      } else {
        setError("An error occured. Please make sure it is a valide url");
      }
    } catch (error) {
      setError("An error occured. Please make sure it is a valide url");
    }

    setIsLoading(true);
    setIsLoading(false);
  };
  return (
    <>
      <section className="bg-center bg-no-repeat background h-screen md:pb-16 overflow-hidden">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <img className="h-32 -mb-5" src={Logo} alt="logo" />
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl text-center -mb-2 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Verify your email
              </h1>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={handleSubmit}
              >
                Submit
              </button>
              {error && <div className="text-red-500">{error}</div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default VerifyEmail;
